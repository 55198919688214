import React, { useState, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ShowError from './ShowError';

export default function Condolences ({formName, deceasedName, onSubmitHandler}) {
    
    const [ error, setError ] = useState(0);
    const [ errorType, setErrorType ] = useState(null);
    const [ success, setSuccess ] = useState(0);
    const [ fields, setFields ] = useState({
        In_Memory_Of: deceasedName, 
        Message: '',
        First_Name: '',
        Last_Name: '',
        Address_Line_1: '',
        Address_Line_2: '',
        City: '',
        Province: '',
        Postal_Code: '',
        Country: 'Canada',
        Phone: '',
        Email: ''
    });

    const updateFieldByType = (key, value, validate = false, validate_message = null) => {

        let valid = true;

        if (validate) {
            if(value.match(validate)!=null) {
                setError(validate_message)
                setErrorType(key)
                valid = false;
            }
        }

        if (valid) {
            let newFields = {...fields}
            newFields[key] = encodeURIComponent(value.replace(/[\r\n\v\"\']+/g, ''));
            setFields(newFields);
        }

    }

    const formSubmitHandler = async (e) => {
        e.preventDefault();

        const response = await onSubmitHandler({
            endpoint: `${formName}`,
            fields,
        })

        if (response.data.error) {
            setError(response.data.message);
            setErrorType('above')
        }

        if (response.data.success) {
            setSuccess(true);
        }
    }

    if (success) {
        return (
            <div className="confirmation">
                <h1 className="confirmation-title">Thank you for your message of condolence, {decodeURIComponent(fields["First_Name"])}</h1>
                <p>Your submission has been recieved. You can close this window.</p>
            </div>
        )
    }

    return (
        <Form>
            <h1>Send your condolences to the family of: {deceasedName}</h1>
            <br />
            <ShowError error={error} errorType={errorType} type={'above'} />
            <Form.Group as={Row} controlId="FirstName">
                <Form.Label as={Col} md="4">First Name</Form.Label>
                <Col md="8">
                    <Form.Control type="text" placeholder="First Name" value={decodeURIComponent(fields["First_Name"])} onChange={(e) => updateFieldByType('First_Name', e.target.value)} />
                    <span className="required">* This field is required</span>
                    <ShowError error={error} errorType={errorType} type={`First_Name`} className={`small`} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="LastName">
                <Form.Label as={Col} md="4">Last Name</Form.Label>
                <Col md="8">
                    <Form.Control  type="text" placeholder="Last Name" value={decodeURIComponent(fields["Last_Name"])} onChange={(e) => updateFieldByType('Last_Name', e.target.value)} />
                    <span className="required">* This field is required</span>
                    <ShowError error={error} errorType={errorType} type={`Last_Name`} className={`small`} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="Email">
                <Form.Label as={Col} md="4">Email address</Form.Label>
                <Col md="8">
                    <Form.Control type="email" placeholder="Enter email" value={decodeURIComponent(fields["Email"])} onChange={(e) => updateFieldByType('Email', e.target.value)} />
                    <ShowError error={error} errorType={errorType} type={`Email`} className={`small`} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="Phone">
                <Form.Label as={Col} md="4">Phone</Form.Label>
                <Col md="8">
                    <Form.Control md="8" type="telephone" placeholder="Enter Phone" value={decodeURIComponent(fields["Phone"])} onChange={(e) => updateFieldByType('Phone', e.target.value)} />
                    <ShowError error={error} errorType={errorType} type={`Phone`} className={`small`} />
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="Address">
                <Col md="4">
                    <Form.Label>Address</Form.Label>
                </Col>
                <Col md="8">
                    <Row>
                        <Form.Row as={Col} md="12" className="field-pad-top">
                            <Form.Label>Address Line 1</Form.Label>
                            <Form.Control type="text" placeholder="Address Line 1" value={decodeURIComponent(fields["Address_Line_1"])} onChange={(e) => updateFieldByType('Address_Line_1', e.target.value)} />
                        </Form.Row>
                        <Form.Row as={Col} md="12" className="field-pad-top">
                            <Form.Label>Address Line 2</Form.Label>
                            <Form.Control type="text" placeholder="Address Line 2" value={decodeURIComponent(fields["Address_Line_2"])} onChange={(e) => updateFieldByType('Address_Line_2', e.target.value)} />
                        </Form.Row>
                        <Form.Row as={Col} md="4" className="field-pad-right">
                            <Form.Label>City</Form.Label>
                            <Form.Control type="text" placeholder="City" value={decodeURIComponent(fields["City"])} onChange={(e) => updateFieldByType('City', e.target.value)} />
                        </Form.Row>
                        <Form.Row as={Col} md="4" className="field-pad-right">
                            <Form.Label>Province</Form.Label>
                            <Form.Control type="text" placeholder="Province" value={decodeURIComponent(fields["Province"])} onChange={(e) => updateFieldByType('Province', e.target.value)} />
                        </Form.Row>
                        <Form.Row as={Col} md="4" className="field-pad-right">
                            <Form.Label>Postal Code</Form.Label>
                            <Form.Control type="text" placeholder="PC" value={decodeURIComponent(fields["Postal_Code"])} onChange={(e) => updateFieldByType('Postal_Code', e.target.value)} />
                        </Form.Row>
                        <Form.Row as={Col} md="4" className="field-pad-right">
                            <Form.Label>Country</Form.Label>
                            <Form.Control type="text" placeholder="Country" value={decodeURIComponent(fields["Country"])} onChange={(e) => updateFieldByType('Country', e.target.value)} />
                        </Form.Row>
                    </Row>
                </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="Message">
                <Form.Label>Message</Form.Label>
                <Form.Control type="text" as="textarea" rows={3} placeholder="Enter Message" value={decodeURIComponent(fields["Message"])} onChange={(e) => updateFieldByType('Message', e.target.value)} />
            </Form.Group>
            <br />
            <Form.Group as={Row} controlId="Submit">
                <Button variant="primary" type="submit" onClick={(e) => formSubmitHandler(e)}>
                    Submit
                </Button>
            </Form.Group>
            
        </Form>
    )
}