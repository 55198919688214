import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import React, { useEffect, useState, useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import axios from "axios";
import {Helmet} from "react-helmet";

import Condolences from './forms/Condolences';
import Donations from './forms/Donations';

const serverAddress = 'https://donations.macleanfh.ca/Submit';
//const serverAddress = 'https://35.164.134.97:4000';
//const serverAddress = 'https://localhost:4000';

function App() {

  const [charityNames, setCharityNames] = useState(false);

  // on init, pull in charity names from API
  useEffect(() => {
    axios.get(`${serverAddress}/getCharityNames`)
      .then(res=>{
        setCharityNames(res.data.charities);
      })
  }, [])

  return (
    <div className="App">
      <Helmet>
        <title>MacLean Funeral Home - Form</title>
      </Helmet>
      <Router>
        <Switch>
          <Route path="/:formName/:deceasedName/" children={<FormSwitch charities={charityNames} />} />
          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
    </Router>
    </div>
  );
}

export default App;

const FormSwitch = ({charities}) => {

    let { formName, deceasedName } = useParams();

    let forms = {
      'Condolences': Condolences,
      'Donations': Donations
    }

    const ActiveForm = forms[formName];
    // return no match if its not a pre-defined form
    if (ActiveForm === undefined) {
      return <NoMatch />
    }
    
    return ( 
      <div className="form-container">
        <ActiveForm formName={formName} deceasedName={deceasedName} charities={charities} onSubmitHandler={onSubmitHandler} />
      </div>
      
    );
}

const onSubmitHandler = async ({endpoint, fields}) => {
  const json = JSON.stringify(fields);
  return axios.post(`${serverAddress}/${endpoint}/${json}`)
}

const NoMatch = () => (
  <div className="center-container">
    <h1>Sorry, that doesn't seem right. You might want to go back</h1>
  </div>
)