import React from 'react';

export default function ShowError ({error, errorType, type, className = ''}) {

    if (errorType === type) {
        return (
            <div className={`error-message ${className}`}>
                {error}
            </div>
        )
    }

    return null;
}